/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//@import "~@angular/material/theming";//ori
//@import "~@angular/material/prebuilt-themes/deeppurple-amber.css"; //ori
@import "@angular/material/theming";//test 1
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";//test1


ion-modal {
    --width: 100%;
}

app-tab2.ion-page {
  overflow: visible;
}

fieldset {
    border-radius: 8px;
    padding-bottom: 44px;
    legend {
        margin-left: 36px;
        padding: 8px;
    }
}

.transparent-modal {
  --background: #1a1a1aa9;

  ion-modal::part(content) {
    backdrop-filter: blur(12px);
  }

  ion-content {
    --background: transparent;
  }

  &p::part(backdrop){
    background-color: aqua;
  }

  &::part(content) {
    height: 100%;
    margin: auto;
  }
}

/*gere l'espace entre les ion-select option dans la "modale" 1/2*/
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}
/*gere l'espace entre les ion-select option dans la "modale" 2/2*/
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}